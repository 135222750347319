<template>
  
    
    <div class="d-none d-lg-block">
      <Header/>
    </div>
    <div class="d-block d-lg-none">
    <MobileHeader role=""></MobileHeader>
    </div>
     <router-view></router-view>
     <div class="d-none d-lg-block">
    <Footer/>
     </div>
     <div class="d-block d-lg-none">
       <MobileFooter role = ""></MobileFooter>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Header from '../layouts/PreSigninHeader.vue';
import Footer from '../layouts/Footer.vue';
import MobileFooter from '../layouts/CommonFooter.vue'
import MobileHeader from '../layouts/Header.vue'


export default defineComponent({
    components: {
       Header, 
       Footer,MobileFooter,MobileHeader
    },
    setup() {
        
    },
})
</script>
